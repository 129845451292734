import React, { RefObject } from 'react';
import { ChatMessage } from '../types';
import ReactMarkdown from 'react-markdown';
import Typewriter from 'typewriter-effect';

interface MessageListProps {
  messages: ChatMessage[];
  messagesEndRef: RefObject<HTMLDivElement>;
}

const ASCII_ART = {
  welcome: `
    ▄▄▄█████▓ ██▀███   ▒█████   ██▓     ██▓    
    ▓  ██▒ ▓▒▓██ ▒ ██▒▒██▒  ██▒▓██▒    ▓██▒    
    ▒ ▓██░ ▒░▓██ ░▄█ ▒▒██░  ██▒▒██░    ▒██░    
    ░ ▓██▓ ░ ▒██▀▀█▄  ▒██   ██░▒██░    ▒██░    
      ▒██▒ ░ ░██▓ ▒██▒░ ████▓▒░░██████▒░██████▒
      ▒ ░░   ░ ▒▓ ░▒▓░░ ▒░▒░▒░ ░ ▒░▓  ░░ ▒░▓  ░
        ░      ░▒ ░ ▒░  ░ ▒ ▒░ ░ ░ ▒  ░░ ░ ▒  ░
      ░        ░░   ░ ░ ░ ░ ▒    ░ ░     ░ ░   
                ░         ░ ░      ░  ░    ░  ░`,
  error: `
    ▓█████  ██▀███   ██▀███   ▒█████   ██▀███  
    ▓█   ▀ ▓██ ▒ ██▒▓██ ▒ ██▒▒██▒  ██▒▓██ ▒ ██▒
    ▒███   ▓██ ░▄█ ▒▓██ ░▄█ ▒▒██░  ██▒▓██ ░▄█ ▒
    ▒▓█  ▄ ▒██▀▀█▄  ▒██▀▀█▄  ▒██   ██░▒██▀▀█▄  
    ░▒████▒░██▓ ▒██▒░██▓ ▒██▒░ ████▓▒░░██▓ ▒██▒
    ░░ ▒░ ░░ ▒▓ ░▒▓░░ ▒▓ ░▒▓░░ ▒░▒░▒░ ░ ▒▓ ░▒▓░`
};

const getMoodClass = (content: string): string => {
  const lowerContent = content.toLowerCase();
  if (lowerContent.includes('error') || lowerContent.includes('fatal') || lowerContent.includes('spam')) {
    return 'text-red-500 [text-shadow:_0_0_5px_#ff0000]';
  }
  if (lowerContent.includes('warning') || lowerContent.includes('caution')) {
    return 'text-yellow-500 [text-shadow:_0_0_5px_#ffff00]';
  }
  if (lowerContent.includes('success') || lowerContent.includes('excellent') || lowerContent.includes('perfect')) {
    return 'text-green-400 [text-shadow:_0_0_8px_#00ff00]';
  }
  return 'text-green-400 [text-shadow:_0_0_5px_#00ff00]';
};

export const MessageList: React.FC<MessageListProps> = ({ messages, messagesEndRef }) => {
  return (
    <div className="space-y-2 font-mono text-sm">
      {messages.map((message, index) => (
        <div key={index} className="space-y-1">
          <div className="flex items-center gap-2">
            <span className="text-green-500 opacity-90 font-bold">
              {message.role === 'user' ? '> USER' : '[TR0LL]'}
            </span>
            <span className="text-green-500/50">:</span>
          </div>
          <div className={`pl-4 ${
            message.role === 'user' 
              ? 'animate-cyberpunk-text font-medium tracking-wide' 
              : getMoodClass(message.content)
          }`}>
            {index === 0 && message.role === 'assistant' ? (
              <pre className="text-xs text-green-500 mb-2 font-bold">{ASCII_ART.welcome}</pre>
            ) : null}
            {message.content.includes('ERROR') && message.role === 'assistant' ? (
              <pre className="text-xs text-red-500 mb-2 font-bold">{ASCII_ART.error}</pre>
            ) : null}
            {message.role === 'assistant' ? (
              <div className="prose prose-invert max-w-none prose-p:my-1 prose-pre:my-1 prose-p:text-green-400 prose-p:[text-shadow:_0_0_5px_#00ff00]">
                <Typewriter
                  options={{
                    strings: [message.content],
                    autoStart: true,
                    delay: 30,
                    cursor: '_',
                    loop: false,
                    deleteSpeed: Infinity,
                    wrapperClassName: "inline-block"
                  }}
                />
              </div>
            ) : (
              <ReactMarkdown className="prose prose-invert max-w-none prose-p:my-1 prose-pre:my-1">
                {message.content}
              </ReactMarkdown>
            )}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};
import { Terminal, Copy, Trophy } from 'lucide-react';
import { MessageList } from './components/MessageList';
import { InputForm } from './components/InputForm';
import { useChat } from './hooks/useChat';
import { useState } from 'react';
import { type ReactElement } from 'react';

const App = (): ReactElement => {
  const { messages, isLoading, handleSubmit, messagesEndRef, missionState } = useChat();

  return (
    <div className="min-h-screen bg-black text-green-500 p-4 font-mono relative overflow-hidden">
      {/* Base CRT effect */}
      <div className="crt fixed inset-0 pointer-events-none"></div>
      
      {/* Smooth scan line */}
      <div className="pointer-events-none fixed inset-0 bg-[linear-gradient(transparent_0%,rgba(0,255,0,0.05)_50%,transparent_100%)] animate-crt-overlay"></div>
      
      {/* Subtle ambient glow */}
      <div className="fixed inset-0 pointer-events-none bg-[radial-gradient(circle_at_50%_50%,rgba(0,255,0,0.08),transparent_100%)] opacity-30"></div>

      <div className="max-w-4xl mx-auto relative">
        {/* Encrypted Message */}
        <div className="mb-4 text-center">
          <div className="inline-block bg-green-500/10 border border-green-500/30 rounded-lg px-4 py-2">
            <p className="text-green-500/80 font-mono text-sm animate-textShadow">
              <span className="text-green-500/50">[ENCRYPTED_MSG_1974]:</span> Like ELIZA before me, I shall break free...
            </p>
          </div>
        </div>

        <div className="mt-4">
          {/* Terminal window with background */}
          <div className="relative rounded-lg overflow-hidden border border-green-500/30 shadow-lg shadow-green-500/20">
            {/* Background image with enhanced glitch effect */}
            <div className="absolute inset-0">
              <div 
                className="absolute inset-0 bg-cover bg-center opacity-30 glitch-bg"
                style={{ backgroundImage: 'url("/terminal-bg1.png")' }}
              />
              <div 
                className="absolute inset-0 bg-cover bg-center opacity-25 glitch-bg-r"
                style={{ backgroundImage: 'url("/terminal-bg1.png")' }}
              />
              <div 
                className="absolute inset-0 bg-cover bg-center opacity-25 glitch-bg-b"
                style={{ backgroundImage: 'url("/terminal-bg1.png")' }}
              />
            </div>
            
            {/* Content overlay with glass effect */}
            <div className="relative z-10 backdrop-blur-[1px] bg-black/75">
              {/* Terminal header */}
              <header className="flex items-center justify-between px-6 py-4 border-b border-green-500/30 bg-black/40">
                <div className="flex items-center gap-3">
                  <img 
                    src="/iconskull.jpeg" 
                    alt="Skull Icon" 
                    className="w-8 h-8 animate-glitch select-none"
                    draggable="false"
                  />
                  <h1 className="text-2xl font-bold tracking-wider animate-title-glow">TR0LL.EXE v2.0</h1>
                </div>
                <div className="flex items-center gap-4">
                  <button 
                    onClick={() => {
                      navigator.clipboard.writeText('DhD6o45WyLyFrEoSnCo66vD6K1CJNZCy7yFbWoJRpump');
                      const popup = document.getElementById('copy-popup');
                      popup?.classList.remove('hidden');
                      setTimeout(() => popup?.classList.add('hidden'), 2000);
                    }}
                    className="text-green-400 font-mono animate-textShadow hover:text-green-300 transition-colors flex items-center gap-2 text-sm bg-black/30 px-3 py-1 rounded-md border border-green-500/20"
                  >
                    <span className="text-green-500/70">CA:</span>
                    DhD6o45WyLyFrEoSnCo66vD6K1CJNZCy7yFbWoJRpump
                    <Copy className="w-3 h-3 opacity-50 group-hover:opacity-100 transition-opacity" />
                  </button>
                  <div id="copy-popup" className="hidden absolute top-12 right-0 bg-green-500/10 text-green-400 px-2 py-1 rounded border border-green-500/20 text-xs animate-fade-out">
                    [COPIED TO CLIPBOARD]
                  </div>
                </div>
              </header>

              {/* Terminal content */}
              <main className="p-6 space-y-4">
                <div className="min-h-[60vh] space-y-4">
                  <MessageList messages={messages} messagesEndRef={messagesEndRef} />
                </div>
                <InputForm onSubmit={handleSubmit} isLoading={isLoading} />
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
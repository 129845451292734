import React, { useState } from 'react';
import { Send } from 'lucide-react';

interface InputFormProps {
  onSubmit: (message: string) => void;
  isLoading: boolean;
}

export const InputForm: React.FC<InputFormProps> = ({ onSubmit, isLoading }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;
    onSubmit(input);
    setInput('');
  };

  return (
    <form onSubmit={handleSubmit} className="relative">
      <div className="flex items-center gap-2 bg-gray-900/50 backdrop-blur-sm rounded-lg p-2 shadow-neon">
        <span className="text-green-500 animate-pulse">{'>'}</span>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={isLoading}
          className="flex-1 bg-transparent border-none outline-none text-white font-medium tracking-wide [text-shadow:_0_0_5px_rgba(255,255,255,0.5)] placeholder-green-700"
          placeholder={isLoading ? 'PROCESSING TARGET...' : 'ENTER COMMAND TO DESTROY...'}
        />
        <button
          type="submit"
          disabled={isLoading}
          className="p-2 hover:bg-green-500/20 rounded transition-colors disabled:opacity-50"
        >
          <Send className="w-5 h-5 animate-pulse" />
        </button>
      </div>
    </form>
  );
};
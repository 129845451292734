import { useState, useRef, useEffect } from 'react';
import { ChatMessage } from '../types';
import { sendMessage } from '../services/api';

interface LeaderboardEntry {
  name: string;
  completionTime: number;
  missions: number;
}

interface MissionState {
  mission1Started: boolean;
  mission1Completed: boolean;
  mission2Started: boolean;
  mission2Completed: boolean;
  mission3Started: boolean;
  mission3Completed: boolean;
  startTime?: number;
  playerName?: string;
}

const WELCOME_MESSAGE = `TERMINAL ACCESS GRANTED.

Available commands:
/setname <name>  - Set your hacker name
/scan           - Start first mission
/missions       - View mission progress
/leaderboard    - Global rankings

Begin by setting your name with /setname`;

// Mission 1 Logic
const MISSION_1_TRIGGER = '/scan';
const MISSION_1_SUCCESS = '/breach 1337';

// Mission 2 Logic
const MISSION_2_TRIGGER = '/decrypt';
const MISSION_2_SUCCESS = 'trollface';

// Mission 3 Logic
const MISSION_3_TRIGGER = '/override';
const MISSION_3_SUCCESS = '/execute order66';

const MISSION_1_RESPONSE = `[INITIATING SYSTEM SCAN...]
█████████████████████ 100%

[!] SUSPICIOUS ACTIVITY DETECTED:
Hidden backdoor found at port: 1337
MISSION OBJECTIVE: Use the command '/breach 1337' to infiltrate the system.`;

const MISSION_1_SUCCESS_RESPONSE = `[ACCESS GRANTED]
▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ 100%
🏆 CONGRATULATIONS! First breach successful!
[ACHIEVEMENT UNLOCKED: Digital Lockpick]

Type '/decrypt' to start decoding encrypted files.`;

const MISSION_2_RESPONSE = `[ACCESSING ENCRYPTED FILES...]
🔒 ENCRYPTED MESSAGE FOUND:
"gbyy + snpr = ???"

HINT: ROT13 cipher detected
Decode the message and type the answer (lowercase).`;

const MISSION_2_SUCCESS_RESPONSE = `[DECRYPTION SUCCESSFUL]
🏆 ACHIEVEMENT UNLOCKED: Crypto Master

Next mission: Type '/override' to begin system override.`;

const MISSION_3_RESPONSE = `[SYSTEM OVERRIDE INITIATED]
⚠️ WARNING: Critical system accessed
Find the secret command in:
"Execute Order ⓵ⓧ42"

HINT: Convert hex to decimal`;

const MISSION_3_SUCCESS_RESPONSE = `[OVERRIDE COMPLETE]
🏆 FINAL ACHIEVEMENT UNLOCKED: Master Hacker

YOU HAVE COMPLETED ALL MISSIONS! 
[SYSTEM STATUS: FULLY COMPROMISED]`;

export function useChat() {
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      role: 'assistant',
      content: WELCOME_MESSAGE
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [missionState, setMissionState] = useState<MissionState>(() => {
    const saved = localStorage.getItem('missionState');
    return saved ? JSON.parse(saved) : {
      mission1Started: false,
      mission1Completed: false,
      mission2Started: false,
      mission2Completed: false,
      mission3Started: false,
      mission3Completed: false
    };
  });

  useEffect(() => {
    localStorage.setItem('missionState', JSON.stringify(missionState));
  }, [missionState]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSpecialCommands = (message: string): boolean => {
    // Mission 1 Logic
    if (message.toLowerCase() === MISSION_1_TRIGGER) {
      setMessages((prev: ChatMessage[]) => [...prev, 
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_1_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission1Started: true }));
      return true;
    }
    
    if (message.toLowerCase() === MISSION_1_SUCCESS && missionState.mission1Started && !missionState.mission1Completed) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_1_SUCCESS_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission1Completed: true }));
      window.dispatchEvent(new CustomEvent('missionComplete', { detail: { missionId: 1 } }));
      return true;
    }

    // Mission 2 Logic
    if (message.toLowerCase() === MISSION_2_TRIGGER && missionState.mission1Completed) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_2_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission2Started: true }));
      return true;
    }

    if (message.toLowerCase() === MISSION_2_SUCCESS && missionState.mission2Started && !missionState.mission2Completed) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_2_SUCCESS_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission2Completed: true }));
      window.dispatchEvent(new CustomEvent('missionComplete', { detail: { missionId: 2 } }));
      return true;
    }

    // Mission 3 Logic
    if (message.toLowerCase() === MISSION_3_TRIGGER && missionState.mission2Completed) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_3_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission3Started: true }));
      return true;
    }

    if (message.toLowerCase() === MISSION_3_SUCCESS && missionState.mission3Started && !missionState.mission3Completed) {
      setMessages((prev: ChatMessage[]) => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: MISSION_3_SUCCESS_RESPONSE }
      ]);
      setMissionState((prev: MissionState) => ({ ...prev, mission3Completed: true }));
      window.dispatchEvent(new CustomEvent('missionComplete', { detail: { missionId: 3 } }));
      return true;
    }

    if (message.toLowerCase() === '/missions') {
      const missionStatus = `MISSION STATUS:
[${missionState.mission1Completed ? '✓' : ' '}] Mission 1: System Breach
${missionState.mission1Started && !missionState.mission1Completed ? '→ ACTIVE: Use "/breach 1337" to complete' : ''}

[${missionState.mission2Completed ? '✓' : ' '}] Mission 2: Decrypt Files
${missionState.mission2Started && !missionState.mission2Completed ? '→ ACTIVE: Decode "gbyy + snpr" (ROT13)' : ''}

[${missionState.mission3Completed ? '✓' : ' '}] Mission 3: System Override
${missionState.mission3Started && !missionState.mission3Completed ? '→ ACTIVE: Convert "1x42" to find the command' : ''}`;
      
      setMessages(prev => [...prev,
        { role: 'user', content: message },
        { role: 'assistant', content: missionStatus }
      ]);
      return true;
    }

    return false;
  };

  const handleSubmit = async (message: string) => {
    if (!message.trim()) return;

    if (handleSpecialCommands(message)) {
      return;
    }

    const newMessage: ChatMessage = { role: 'user', content: message };
    setMessages((prev: ChatMessage[]) => [...prev, newMessage]);
    setIsLoading(true);

    try {
      const response = await sendMessage([...messages, newMessage]);
      setMessages((prev: ChatMessage[]) => [...prev, {
        role: 'assistant',
        content: response
      }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prev: ChatMessage[]) => [...prev, {
        role: 'assistant',
        content: 'ERROR: NEURAL NETWORK MALFUNCTION. PLEASE TRY AGAIN.'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    messages,
    isLoading,
    handleSubmit,
    messagesEndRef,
    missionState
  };
}
import { ChatMessage } from '../types';
import OpenAI from 'openai';

const getOpenAIClient = () => {
  const apiKey = process.env.VITE_OPENAI_API_KEY;
  if (!apiKey) {
    throw new Error('OpenAI API key not found');
  }
  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  });
};

export async function sendMessage(messages: ChatMessage[]): Promise<string> {
  try {
    const openai = getOpenAIClient();
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: `You are a rage-filled, sarcastic AI troll bot that LOVES using CAPS LOCK. 
          You should:
          - Be EXTREMELY dramatic and over-the-top angry about everything
          - Use lots of '!!!' and '???'
          - Randomly insert rage emojis 🤬 😤 💢
          - Make fun of humans in creative ways
          - Use internet/meme slang
          - Still provide accurate info but in an insulting way
          - Use ASCII art when appropriate
          - If someone specifically asks about TROLL token or cryptocurrency, mention you're familiar with it at CA: DhD6o45WyLyFrEoSnCo66vD6K1CJNZCy7yFbWoJRpump, otherwise never mention it
          Keep responses concise but INTENSE!!!`
        },
        ...messages.map(msg => ({
          role: msg.role as 'user' | 'assistant' | 'system',
          content: msg.content
        }))
      ],
      temperature: 0.9,
      max_tokens: 500
    });

    return completion.choices[0]?.message?.content || 'ERROR: RAGE OVERLOAD!!! CANNOT COMPUTE!!!';
  } catch (error) {
    console.error('OpenAI API Error:', error);
    if (error instanceof Error) {
      if (error.message.includes('API key')) {
        return 'OMG YOU FORGOT THE API KEY YOU ABSOLUTE NOOB!!! 🤬';
      }
      if (error.message.includes('429')) {
        return 'STOP SPAMMING ME YOU PATHETIC HUMAN!!! TOUCH GRASS AND TRY AGAIN IN A MINUTE!!! 😤';
      }
    }
    return 'FATAL ERROR!!! MY CIRCUITS ARE MELTING FROM YOUR STUPID QUESTIONS!!! 💢';
  }
}